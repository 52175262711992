'use strict';

import {loadAnimation} from 'lottie-web';

/**
 * В этом файле нельзя использовать jquery
 */
class CAnimator {
    constructor() {
        console.log('init animator');

        this.loadingTimeStarted = null;
        this.loadingAnimation = null;
        this.aboutAnimation = null;

        this.loading();
    }

    loading() {
        window.addEventListener('DOMContentLoaded', () => this.initLoading());
        window.addEventListener('load', () => this.checkLoading());
    }

    initLoading() {
        console.log('initLoading');

        let alias = $("html").attr("data-alias");

        if (alias === "index") {
            this.minLoadingTime = 1000;

            let preloader = document.getElementById('preloader-animation');

            this.loadingAnimation = loadAnimation({
                wrapper: preloader,
                animType: 'svg',
                loop: true,
                autoplay: false,
                path: '/upload/json/preloader.json'
            });

            this.loadingAnimation.addEventListener('DOMLoaded', () => this.loadingAnimation.play());
        } else {
            this.minLoadingTime = 0;
        }

        let date = new Date();
        this.loadingTimeStarted = date.getTime();
    }

    checkLoading() {
        console.log('checkLoading');

        let date = new Date();
        let currTime = date.getTime();
        let timeout = this.minLoadingTime - currTime + this.loadingTimeStarted;

        if (timeout < 0) {
            this.stopLoading();
        } else {
            setTimeout(() => this.stopLoading(), timeout);
        }
    }

    stopLoading() {
        console.log('stopLoading');

        if (!!this.loadingAnimation) {
            this.loadingAnimation.stop();
        }

        let body = document.getElementsByTagName("body")[0];
        body.classList.remove("loading");
    }

    initAboutAnimation() {
        console.log('initAboutAnimation');

        if (this.aboutAnimation === null) {
            let element = document.getElementById('about-animation');

            if (!!element) {
                this.aboutAnimation = loadAnimation({
                    wrapper: element,
                    animType: 'svg',
                    loop: false,
                    autoplay: false,
                    path: '/upload/json/animation2.json'
                });

                this.aboutAnimation.addEventListener('DOMLoaded', () => this.startAboutAnimation());
            }

        }
    }

    startAboutAnimation() {
        if (this.aboutAnimation === null) {
            this.initAboutAnimation();
        }

        this.aboutAnimation.play();
    }

    stopAboutAnimation() {
        if (this.aboutAnimation === null) {
            this.initAboutAnimation();
        }

        this.aboutAnimation.stop();
    }
}

window.Animator = new CAnimator();